<template lang="pug">
    Aside#radioDownload(:ativo="$store.state.toggleRadioDownload")
        .radioModal
            h3 Acesso exclusivo a rede de rádios
            ul
                li(v-for="informativo, index in reverseList")
                    a(:href="informativo.file" target="_blank" download)
                        .info
                            h4 {{formatDate(informativo.publishedAt)}}
                        .file
                            FontAwesomeIcon(:icon="icons.faDownload")
                            p {{ informativo.name }}
</template>

<script>
import Aside from '@components/Aside/Aside'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

export default {
    name: "component-radio-download",
    components: {
		Aside,
	},
    data () {
        return {
            icons: {
                faDownload,
            },
        }
    },
    methods: {
        formatDate(date) {
            const newDate = date.split('T')[0]
            const [ year, month, day ] = newDate.split('-')
            return `${day}/${month}/${year}`
        }
    },
    computed: {
        reverseList() {
            return this.$store.state.programs
                .reverse()
                .slice(0, 5)
                .map(program => ({
                    ...program,
                    file: `${process.env.VUE_APP_FILE_API_ROUTE}/files/download/${program.file.id}`
                }))
        }
    },
}
</script>

<style lang="stylus" scoped src="./RadioDownload.styl"></style>
