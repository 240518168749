import { render, staticRenderFns } from "./InformativoAcessos.vue?vue&type=template&id=c28b99ca&scoped=true&lang=pug&"
import script from "./InformativoAcessos.vue?vue&type=script&lang=js&"
export * from "./InformativoAcessos.vue?vue&type=script&lang=js&"
import style0 from "./InformativoAcessos.styl?vue&type=style&index=0&id=c28b99ca&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c28b99ca",
  null
  
)

export default component.exports