<template lang="pug">
    Aside#radioLogin(:ativo="$store.state.toggleRadio" #default="{ beforeClose }")
        .modal
            form(@submit="access").modalLogin
                h3 Acesso exclusivo a rede de rádios
                .inputs
                    input(
                        :class="{ fail : fail}"
                        type="text" 
                        placeholder="Email" v-model="user"
                    )
                    input(
                        :class="{ fail : fail}"
                        type="password" placeholder="Senha" 
                        v-model="password"
                    )
                .buttons
                    button.acessar(type="submit") Acessar
                    button.cancelar(type="button" @click="beforeClose") Cancelar
</template>

<script>
import Aside from '@components/Aside/Aside'
import axios from 'axios'

export default {
    name: "component-radio-login",
    components: {
		Aside,
	},
    data() {
        return {
            user: '',
            password: '',
            fail: false,
        }
    },
    methods: {
        async access(e) {
            e.preventDefault()

            try {
				await axios.post(`${process.env.VUE_APP_API_ROUTE}/auth/login/programs`, {
					username: this.user,
					password: this.password,
					role: 'programa',
				})
                .then(data => {
                    this.$store.commit('toggleRadioAside', false)
                    this.$store.commit('toggleRadioDownloadAside', true)
                    this.$store.dispatch('fetchPrograms', data.data.acces_token)
                })
			}
			catch (e) {
				console.error(e)
			}
        },
    },

}
</script>

<style lang="stylus" scoped src="./RadioLogin.styl"></style>
