<template lang="pug">
    #informativoAcessos
        ul.listaAcessos
            li.itemAcesso
                button(@click="active = !active")
                    img(:src="images.ultimos")
                    p Últimos Informativos
            li(@click="radio").itemAcesso
                img(:src="images.radios")
                p Acesso exclusivo à rede de ràdios
            li.itemAcesso
                a(href="https://coamo.podbean.com/" target="_blank")
                    img(:src="images.podcasts")
                    p Podcasts
        transition-group(name="fade")
            ul(v-if="active" :class="{active: 'active'}" key="ul").listaInformativos
                li(v-for="item, index in filteredList" v-if="index < 5").itemInformativo
                    .header(@click="toggleFrame(index)")
                        .titles
                            p.name {{ item.name }}
                        FontAwesomeIcon(:icon="icons.faChevronDown" :class="{ openIcon: item.isOpen }")
                    div(:class="{ open: item.isOpen }").iframeContainer
                        p.description {{ item.description }}
                        <iframe style="border-radius:12px" :src="item.href" width="60%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
        Radio
        RadioDownload
</template>

<script>
import Radio from '@components/RadioLogin/RadioLogin'
import RadioDownload from '@components/RadioDownload/RadioDownload'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
require("dotenv").config();

export default {
    name: "component-informativo-acessos",
    components: {
        Radio,
        RadioDownload,
	},
    data() {
        return {
            images: {
                'ultimos': require(`@images/paginas/informativo-coamo/ultimos-informativos.png`),
                'radios': require(`@images/paginas/informativo-coamo/rede-radios.png`),
                'podcasts': require(`@images/paginas/informativo-coamo/podcasts.png`),
            },           
            icons: {
                faChevronDown,
            },
            loginRadios: {},
            lastInformativos: [],
            access_token: '',
            active: false,
            lista: '',
            client_id: '',
            secret: '',
        }
    },
    methods: {
        radio() {
            this.$store.commit('toggleRadioAside', true)
        },
        toggleFrame(index) {
            this.lastInformativos[index].isOpen = !this.lastInformativos[index].isOpen
        },
        async getAuth () {
            for (let setting of this.settings) {
                if (setting.attribute === 'idSpotify') this.client_id = setting.value;
                if (setting.attribute === 'apiSpotify') this.secret = setting.value;
            }
            const auth_token = Buffer.from(`${this.client_id}:${this.secret}`, `utf-8`).toString('base64')

            const token_url = 'https://accounts.spotify.com/api/token'
            const data = 'grant_type=client_credentials'

            await axios.post(token_url, data, {
                headers: {
                    'Authorization': `Basic ${auth_token}`,
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((data) => {
                this.access_token = data.data.access_token
                this.getInformativos()
            })
        },
        async getInformativos() {
            const response = await this.$axios.get(`https://api.spotify.com/v1/shows/3knJuO7xMpM91FgPUxnBsr/episodes`, {
                headers: {
                    Authorization: `Bearer ${this.access_token}`
                },
                params: {
                    limit: `10`,
                    market: `BR`,
                }
            })
            this.lastInformativos = response.data.items.map((item, index) => ({
                name: item.name,
                href: `https://open.spotify.com/embed/episode/${item.id}?utm_source=generator`,
                description: item.description,
                isOpen: false,
            }))
        }
    },
    computed: {
        filteredList() {
			this.lista = this.lastInformativos.filter(informativo => {
				if (!informativo.name.includes('Momento Coamo'))
					return true
			})
			return this.lista
		},
        settings() {
            return this.$store.state.settings
        }
    },
    created() {
        this.$store.dispatch('fetchSettings')
    },
    watch: {
        settings: function () {
            this.getAuth()
        }
    }
}
</script>

<style lang="stylus" scoped src="./InformativoAcessos.styl"></style>